var render = function render(){var _vm=this,_c=_vm._self._c;return _c('StatisticsBox',{attrs:{"title":_vm.$t('usage')},scopedSlots:_vm._u([{key:"table",fn:function(){return [_c('table',{staticClass:"table table-bordered table-striped"},[_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("resource")))]),_c('th',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t("amount")))]),_c('th',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t("limit")))])])]),_c('tbody',_vm._l((_vm.resources),function(resource,index){return _c('tr',{key:index,class:{ group: resource.isGroup, 'group-item': resource.group },attrs:{"title":_vm.getResourceTitle(resource)},on:{"click":function($event){return _vm.toggleResourceGroup(resource.name)}}},[(resource.group ? _vm.isOpen(resource.group) : true)?[_c('td',{key:"name",class:{
                'resource-name': resource.isGroup || !resource.group
              }},[(resource.isGroup)?_c('i',{class:[
                  'fa',
                  _vm.isOpen(resource.name)
                    ? 'fa-chevron-down'
                    : 'fa-chevron-right'
                ]}):_vm._e(),(resource.icon)?_c('Icon',{staticClass:"icon",attrs:{"name":resource.icon}}):_vm._e(),_vm._v(" "+_vm._s(_vm.$tc(resource.name, 2))+" "),(resource.isGroup && _vm.groups[resource.name].asterisk)?_c('span',{staticClass:"text-warning"},[_vm._v("*")]):_vm._e()],1),_c('td',{key:"amount",staticClass:"text-center"},[_vm._v(_vm._s(resource.amount))]),_c('td',{key:"limit",staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t(resource.limit.toString()))+" ")])]:_vm._e()],2)}),0)]),_c('h6',{staticStyle:{"margin":"2em 1.5em 1em"}},[_c('span',{staticClass:"text-warning"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("device_means_connectors_text"))+" ")])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }