import Vue from "vue";
import VueResource from "vue-resource";
import Auth from "@/services/auth.js";

Vue.use(VueResource);
/*
public methods:
  fetch - request a list of items
  get - request a single item
  save - request to save or update a single item
  remove - request to remove a single item
  duplicate - request an item copy
  */

const baseurl = ""; //v1  set to "" after conversion

export default class BillingService {
  async invoices(query, id) {
    return new Promise((resolve) => {
      let url = "";
      if (id) url = `${baseurl}billing/invoices/${id}/\?`;
      else url = `${baseurl}billing/invoices/?`;
      let auth = new Auth();
      if (query) {
        for (var prop in query) {
          let vlr = encodeURIComponent(query[prop]);
          url += `&${prop}=${vlr}`;
        }
      }
      let request = Vue.http.get(url, auth.requestOptions());
      request.then(
        (response) => {
          if (response && response.body) {
            resolve(response.body);
            return;
          }
          resolve(null);
        },
        (error) => {
          //console.log(error);
          let body = ("body" in error && error.body) || {};
          let msg = [];
          for (var i in body) {
            msg.push(i + ": " + body[i]);
          }
          resolve(msg.join("\n"));
        }
      );
    });
  }

  async resend_invoice_email(id) {
    return new Promise((resolve) => {
      let url = `${baseurl}billing/invoices/${id}/commands/`;
      let auth = new Auth();
      let payload = { send_email: true };
      let request = Vue.http.post(url, payload, auth.requestOptions());
      request.then(
        (response) => {
          if (response && response.body) {
            resolve(response.body);
            return;
          }
          resolve(null);
        },
        (error) => {
          resolve(
            error.body?.detail ||
              parseMessage(error.body ?? {}) ||
              "Unknown error"
          );
        }
      );
    });
  }

  async tax_invoices(query, id) {
    return new Promise((resolve) => {
      let url = "";
      if (id) url = `${baseurl}billing/tax_invoices/${id}/\?`;
      else url = `${baseurl}billing/tax_invoices/?`;

      let auth = new Auth();
      if (query) {
        for (var prop in query) {
          let vlr = encodeURIComponent(query[prop]);
          url += `&${prop}=${vlr}`;
        }
      }
      let request = Vue.http.get(url, auth.requestOptions());
      request.then(
        (response) => {
          if (response && response.body) {
            resolve(response.body);
            return;
          }
          resolve(null);
        },
        (error) => {
          //console.log(error);
          let body = ("body" in error && error.body) || {};
          let msg = [];
          for (var i in body) {
            msg.push(i + ": " + body[i]);
          }
          resolve(msg.join("\n"));
        }
      );
    });
  }
}
