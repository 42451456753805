<template>
  <div>
    <input
      :ref="id"
      type="text"
      class="form-control input-money"
      :id="id"
      maxlength="15"
      placeholder="R$ 0,00"
      v-model="formattedValue"
      :style="{ width: width ? width : 'inherited' }"
      @focus="setCaretOnEnd"
      @click="setCaretOnEnd"
      @keydown="onKeyDown"
      @paste.prevent="onPaste"
      :disabled="disabled"
      enterkeyhint="done"
    />
  </div>
</template>

<script>
import { onlyNumbers } from "@/plugins/utils.js";

export default {
  name: "InputMoney",
  props: {
    id: {
      type: String,
      required: false,
      default: () => ""
    },
    value: {
      type: [Number, String],
      required: false,
      default: () => ""
    },
    focus: {
      type: Boolean,
      default: false,
      required: false
    },
    width: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      digits: this.valueToDigits(this.value),
      hasFocus: false
    };
  },
  computed: {
    iValue() {
      let v = this.digitsToDecimalString(this.digits);
      if (!v) return null;
      return parseFloat(v);
    },
    formattedValue: {
      get() {
        return this.$utils.formatMoney(this.iValue);
      },
      set(value) {
        this.digits = this.valueToDigits(value);
        this.$emit("input", this.iValue);
      }
    }
  },
  watch: {
    hasFocus(n) {
      if (this.$el) {
        this.$emit(n ? "focus" : "blur");
      }
    },
    value: {
      handler(n) {
        let new_digits = this.valueToDigits(n);
        if (this.digits == new_digits) return;
        this.digits = new_digits;
      },
      immediate: true
    },
    digits(n) {
      if (n != this.digits) {
        this.$emit("input", this.iValue);
      }
    }
  },
  methods: {
    adjustDecimalString(value) {
      if ((!value && value != 0) || typeof value == "undefined") return null;
      let v = "";
      if (typeof value != "string") {
        v = (value + "").trim();
      } else {
        v = value.slice();
      }
      if (!v || v == "") return null;

      let dotPos = v.indexOf(".");

      if (dotPos == -1) v += ".00";
      else if (v.length - dotPos == 1) v += "00";
      else if (v.length - dotPos == 2) v += "0";
      else if (v.length - dotPos > 3) v = v.slice(0, dotPos + 3);
      return v;
    },
    valueToDigits(value) {
      if ((!value && value != 0) || typeof value == "undefined") return [];
      let v = this.adjustDecimalString(value);
      let result = onlyNumbers(v);
      if (parseInt(result) == 0) result = "0";
      return result.split("");
    },
    digitsToDecimalString(digits) {
      if (!Array.isArray(digits) || !digits || !digits.length) return null;
      let v = digits.slice(0, digits.length);
      while (v.length < 3) {
        v.unshift("0");
      }
      let result = v.join("").trim();
      if (result == "") return null;
      result =
        result.slice(0, result.length - 2) +
        "." +
        result.slice(result.length - 2, result.length);
      return result;
    },
    setCaretOnEnd() {
      this.$utils.setCaretPosition(this.$el, this.formattedValue.length);
    },
    onKeyDown(e) {
      let code = e.keyCode || e.which;
      if (code >= 96 && code <= 105) code -= 48;
      let char = String.fromCharCode(code);

      const KEY_BACKSPACE = 8;
      const KEY_TAB = 9;
      const KEY_DELETE = 46;
      const KEY_C = 67;
      const KEY_V = 86;
      const KEY_X = 88;

      let pos_start = e.target.selectionStart;
      let pos_end = e.target.selectionEnd;
      let size = e.target.value.length;
      let all_selected = pos_start == 0 && pos_end == size;

      if (
        code == KEY_TAB ||
        (e.ctrlKey && (code == KEY_C || code == KEY_V || code == KEY_X))
      ) {
        return;
      } else if (code == KEY_DELETE) {
        if (all_selected) {
          this.digits = [];
        }
      } else if (code == KEY_BACKSPACE) {
        if (all_selected) {
          this.digits = [];
        } else {
          if (this.digits.length) this.digits.pop();
        }
      } else if (onlyNumbers(char) != "") {
        if (all_selected) {
          this.digits = [char];
        } else if (char == "0" && parseInt(this.digits.join("")) == 0) {
          this.digits = [char];
        } else {
          this.digits.push(char);
        }
      }

      while (this.digits.length > 3 && this.digits[0] == "0") {
        this.digits.shift();
      }

      this.$emit("input", this.iValue);
      e.preventDefault();
      e.stopPropagation();
    },
    onPaste(e) {
      let n = e.clipboardData.getData("text");
      this.digits = this.valueToDigits(n);
      this.$emit("input", this.iValue);
    }
  },
  mounted() {
    if (this.focus) {
      this.$nextTick(() => {
        if (this?.$refs[this.id].focus) {
          this.$refs[this.id].focus();
        }
      });
    }
  }
};
</script>

<style scoped>
</style>
