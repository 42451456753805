<template>
  <StatisticsBox :title="computedTitle">
    <h1 class="text-center" v-if="showTotalUsage">
      <strong> {{ totalUsage }}</strong>
    </h1>
    <BaseButton
      class="btn-lg upgrade-btn"
      type="primary"
      tag="a"
      @click="upgradePlan"
    >
      <i class="fa fa-arrow-circle-up"></i>
      <span>{{ $t("upgrade_plan") }}</span>
    </BaseButton>
  </StatisticsBox>
</template>

<script>
import StatisticsBox from "@/components/statistics/statistics-box";
import BaseButton from "@/components/base/buttons/base-button";

import { mapState } from "vuex";
export default {
  name: "CurrentPlanUsage",
  components: {
    StatisticsBox,
    BaseButton
  },
  props: {
    maxCustomPlanData: {
      type: Number,
      required: false,
      default: () => 0
    }
  },
  computed: {
    title() {
      return `${this.$t("current_plan")}: ${this.plan.name ??
        this.$t("not_available")}`;
    },
    billingAmount() {
      return Number(this.plan.amount_to_be_paid ?? 0).toLocaleString(
        this.$i18n.locale,
        { style: "currency", currency: "BRL" }
      );
    },
    contract() {
      return this.$store.getters["user/contract"] || null;
    },
    contractMaximumData() {
      return this.contract?.maximum_data;
    },
    showTotalUsage() {
      return !this.maxCustomPlanData || this.contractMaximumData < this.maxCustomPlanData;
    },
    computedTitle() {
      if (this.showTotalUsage) {
        return this.$t("total_usage");
      } else {
        return this.$t("plan_update");
      }
    },
    totalUsage() {
      let usageSum = this.limitedResources.reduce(
        (sum, resource) => sum + resource.usage.value,
        0
      );
      let quotaSum = this.limitedResources.reduce(
        (sum, resource) => sum + resource.quota.value,
        0
      );
      let result = usageSum / quotaSum;
      if (typeof result != "number" || !result && result != 0) result = 0;
      return Number(result).toLocaleString(this.$i18n.locale, {
        style: "percent"
      });
    },
    limitedResources() {
      return this.contractResources.filter(
        ({ quota: { value }, name }) =>
          name != "history" && typeof value == "number" && value < 1000000
      );
    },
    ...mapState("user", {
      plan: (state) => state.loggedUser?.contract?.billing?.plan ?? {},
      isYearly: (state) => state.loggedUser?.contract?.is_yearly,
      contractResources: (state) =>
        state.loggedUser?.contract?.billing?.resources ?? []
    })
  },
  methods: {
    upgradePlan() {
      this.$emit("upgrade-plan");
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep p {
  font-size: 1.6rem;
}

::v-deep .box-body {
  display: flex;
  flex-direction: column;
}

.upgrade-btn {
  margin: auto auto 0;
  display: block;
  width: 95%;
  border-radius: 10px;

  span {
    // font-size: 1.3em;
  }

  i {
    margin-right: 0.5em;
    font-size: 1.5em;
    vertical-align: bottom;
  }
}
</style>
