<template>
  <form class="me" action="" autocomplete="off" v-on:submit.prevent="submit">
    <div class="row">
      <div class="col-md-6">
        <div class="form-group" :class="{ 'has-error': !isValidCompanyName }">
          <label for="company_name">
            <Icon :name="invalidIconClassName" v-if="!isValidCompanyName" />
            {{ $t("company_name") }}
          </label>
          <input
            type="text"
            class="form-control"
            maxlength="200"
            v-model="company_name"
            :disabled="busy"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group" :class="{ 'has-error': !isValidCpfCnpj }">
          <label for="cpf_cnpj">
            <Icon :name="invalidIconClassName" v-if="!isValidCpfCnpj" />
            {{ $t("cpf_cnpj") }}
          </label>
          <InputMasked
            id="cpf_cnpj"
            type="cpf_cnpj"
            v-model="cpf_cnpj"
            :disabled="busy"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="municipal_registration">
            {{ $t("municipal_registration") }}
          </label>
          <input
            type="text"
            class="form-control"
            maxlength="20"
            v-model="municipal_registration"
            :disabled="busy"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="state_registration">
            {{ $t("state_registration") }}
          </label>
          <input
            type="text"
            class="form-control"
            maxlength="20"
            v-model="state_registration"
            :disabled="busy"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group" :class="{ 'has-error': !isValidPhone }">
          <label for="phone">
            <Icon :name="invalidIconClassName" v-if="!isValidPhone" />
            {{ $t("area_plus_phone") }}
          </label>
          <InputMasked
            id="phone"
            type="phone"
            v-model="phone"
            :disabled="busy"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group" :class="{ 'has-error': !isValidZipcode }">
          <label for="zipcode">
            <Icon :name="invalidIconClassName" v-if="!isValidZipcode" />
            {{ $t("zipcode") }}
          </label>
          <InputMasked
            id="zipcode"
            type="zipcode"
            v-model="zipcode"
            :disabled="busy"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group" :class="{ 'has-error': !isValidNumber }">
          <label for="number">
            <Icon :name="invalidIconClassName" v-if="!isValidNumber" />
            {{ $t("number") }}
          </label>
          <input
            type="text"
            class="form-control"
            id="number"
            maxlength="10"
            v-model="number"
            :disabled="busy"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group" :class="{ 'has-error': !isValidComplement }">
          <label for="complement">
            <Icon :name="invalidIconClassName" v-if="!isValidComplement" />
            {{ $t("complement") }}
          </label>
          <input
            type="text"
            class="form-control"
            id="complement"
            maxlength="20"
            v-model="complement"
            :disabled="busy"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group" :class="{ 'has-error': !isValidEmailBilling }">
          <label for="email_billing">
            <Icon :name="invalidIconClassName" v-if="!isValidEmailBilling" />
            {{ $t("email_billing") }}
          </label>
          <input
            type="text"
            class="form-control"
            id="email_billing"
            maxlength="100"
            v-model="email_billing"
            :disabled="busy"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <button
          class="btn btn-primary"
          @click.stop.prevent="onUpdateProfile"
          :disabled="!isProfileValid"
        >
          {{ $t("save") }}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import Icon from "@/components/icons/icon";
import MixinAlert from "@/project/mixin-alert.js";
import errorMessages from "@/i18n/errors.js";
import InputMasked from "@/components/input-masked.vue";
import { onlyNumbers } from "@/plugins/utils";

export default {
  name: "UserProfileBillingInformation",
  mixins: [MixinAlert],
  components: {
    InputMasked,
    Icon
  },
  i18n: {
    messages: errorMessages
  },
  data() {
    return {
      company_name: "",
      cpf_cnpj: "",
      municipal_registration: "",
      state_registration: "",
      phone: "",
      zipcode: "",
      number: "",
      complement: "",
      email_billing: "",
      invalidIconClassName: "fa fa-exclamation-triangle ",
      busy: false
    };
  },
  computed: {
    loggedUser() {
      return this.$store.getters["user/loggedUser"] || {};
    },
    isProfileValid() {
      return (
        !this.busy && 
        this.isValidCompanyName &&
        this.isValidCpfCnpj &&
        this.isValidPhone &&
        this.isValidZipcode &&
        this.isValidNumber &&
        this.isValidComplement &&
        this.isValidEmailBilling
      );
    },
    isValidCompanyName() {
      return this.company_name && this.company_name.trim() != "";
    },
    isValidCpfCnpj() {
      return this.$utils.validateCpfCnpj(this.cpf_cnpj);
    },
    isValidPhone() {
      return this.$utils.validatePhone(this.phone);
    },
    isValidZipcode() {
      return this.$utils.validateZipcode(this.zipcode);
    },
    isValidNumber() {
      return this.number && this.number.trim() != "";
    },
    isValidComplement() {
      return true;
    },
    isValidEmailBilling() {
      return this.$utils.validateEmail(this.email_billing);
    }
  },
  watch: {
    loggedUser: {
      handler(n) {
        if (n) {
          this.first_name = n.first_name;
          this.last_name = n.last_name;
        }
      },
      deep: true,
      immediate: true
    },
    busy(n) {
      this.$emit("loading", n);
    }
  },
  methods: {
    onUpdateProfile() {
      if (!this.isProfileValid) return;
      this.busy = true;
      let payload = {
        user_profile: {
          company_name: this.company_name,
          municipal_registration: this.municipal_registration,
          state_registration: this.state_registration,
          cpf_cnpj: onlyNumbers(this.cpf_cnpj),
          phone: onlyNumbers(this.phone),
          zipcode: onlyNumbers(this.zipcode),
          number: this.number,
          complement: this.complement,
          email_billing: this.email_billing
        }
      };
      this.$store.dispatch("user/updateProfile", payload).then((ret) => {
        this.busy = false;
        if (this.validateSaveResponse(ret)) {
          this.showAlert(() => {});
        } else {
          this.showAlert();
        }
      });
    },
    submit() {
      if (this.busy) return;
      self.showAlert();
    }
  },
  mounted() {
    this.company_name = this.loggedUser.user_profile.company_name || '';
    this.cpf_cnpj = this.loggedUser.user_profile.cpf_cnpj || '';
    this.municipal_registration = this.loggedUser.user_profile.municipal_registration || '';
    this.state_registration = this.loggedUser.user_profile.state_registration || '';
    this.phone = this.loggedUser.user_profile.phone || '';
    this.zipcode = this.loggedUser.user_profile.zipcode || '';
    this.number = this.loggedUser.user_profile.number || '';
    this.complement = this.loggedUser.user_profile.complement || '';
    this.email_billing = this.loggedUser.user_profile.email_billing || '';
  }
};
</script>

<style scoped>
.me {
  padding-bottom: 20px;
}
.form-control-feedback {
  pointer-events: all;
}
</style>
